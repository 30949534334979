var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.organization'))+": "+_vm._s(_vm.organization.name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('titles.survey'))+": "+_vm._s(_vm.checkLang(_vm.survey.name))+" ")]},proxy:true},(_vm.hasAccess('votes', 'create'))?{key:"button",fn:function(){return [_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"success"},on:{"click":_vm.openModalToCreate}},[_vm._v(" "+_vm._s(_vm.$t('add.question'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"informations-draggable-table"},[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.questionsTableHeader),function(title,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(title.label)+" ")])}),0)]),_c('draggable',{attrs:{"handle":".handle","tag":"tbody"},on:{"change":_vm.updatePosition},model:{value:(_vm.computedQuestions),callback:function ($$v) {_vm.computedQuestions=$$v},expression:"computedQuestions"}},_vm._l((_vm.computedQuestions),function(item,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"handle cursor-move"},[_c('feather-icon',{attrs:{"icon":"AlignJustifyIcon"}})],1)]),_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.checkLang(item.name))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.checkLang(item.hint))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.answers_count)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.position)+" ")]),_c('td',[_c('feather-icon',{class:[
                                    'stroke-current',
                                    _vm.generateIconColorToBoolean(item.is_draft)
                                ],attrs:{"icon":_vm.generateIconToBoolean(item.is_draft)}})],1),_c('td',[_c('feather-icon',{class:[
                                    'stroke-current',
                                    _vm.generateIconColorToBoolean(item.is_multiple)
                                ],attrs:{"icon":_vm.generateIconToBoolean(item.is_multiple)}})],1),_c('td',[_c('feather-icon',{class:[
                                    'stroke-current',
                                    _vm.generateIconColorToBoolean(item.is_required)
                                ],attrs:{"icon":_vm.generateIconToBoolean(item.is_required)}})],1),_c('td',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.open')))),expression:"`${$t('button.open')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"to":{
                                        name: 'answers',
                                        params: {
                                            organizationID: _vm.$route.params.organizationID,
                                            surveyID: _vm.$route.params.surveyID,
                                            questionID: item.id
                                        }
                                    },"variant":"flat-dark"}},[_c('feather-icon',{attrs:{"icon":"ArrowRightCircleIcon"}})],1),_c('table-action-btns',{attrs:{"delete-access":{ section: 'votes', permission: 'delete' },"delete-handler":_vm.deleteQuestion,"index":index,"item":item,"update-access":{ section: 'votes', permission: 'update' }},on:{"openModal":_vm.updateWithData}})],1)])])}),0)],1)])]),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":false,"no-close-on-backdrop":true,"title":_vm.$t('add.survey'),"cancel-variant":"danger","centered":"","size":"xl"},on:{"hidden":_vm.onCloseModal,"show":_vm.onOpenModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.modalSubmit}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6","sm":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.question'))+" (UZ) ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.question')) + " UZ"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " UZ"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6","sm":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.question'))+" (RU) ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.question')) + " RU"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " RU"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6","sm":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.hint'))+" (UZ) ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.hint')) + " UZ"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " UZ"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.hint.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.hint, "uz", $$v)},expression:"dataForModal.hint.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6","sm":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.hint'))+" (RU) ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.hint')) + " RU"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " RU"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.hint.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.hint, "ru", $$v)},expression:"dataForModal.hint.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"3","sm":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.position'))+" ")]),_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.position'),"size":"lg"},model:{value:(_vm.dataForModal.position),callback:function ($$v) {_vm.$set(_vm.dataForModal, "position", $$v)},expression:"dataForModal.position"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"3","sm":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.award_coins'))+" ")]),_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":("" + (_vm.$t('enter.position'))),"size":"lg"},model:{value:(_vm.dataForModal.award_coins),callback:function ($$v) {_vm.$set(_vm.dataForModal, "award_coins", $$v)},expression:"dataForModal.award_coins"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"3","sm":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.timer'))+" ")]),_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":("" + (_vm.$t('enter.position'))),"size":"lg"},model:{value:(_vm.dataForModal.timer),callback:function ($$v) {_vm.$set(_vm.dataForModal, "timer", $$v)},expression:"dataForModal.timer"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"3","sm":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.input_types'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.input_types'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{class:[
                                    'select-size-lg',
                                    {
                                        'v-select--is-invalid': errors.length > 0
                                    }
                                ],attrs:{"menu-props":{
                                    closeOnContentClick: false
                                },"options":_vm.inputTypes,"placeholder":_vm.$t('choose.type'),"reduce":function (type) { return type.id; },"searchable":false,"label":"name"},model:{value:(_vm.dataForModal.input_type_id),callback:function ($$v) {_vm.$set(_vm.dataForModal, "input_type_id", $$v)},expression:"dataForModal.input_type_id"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"4","lg":"3","sm":"3"}},[_c('label'),_c('b-form-checkbox',{model:{value:(_vm.dataForModal.is_multiple),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_multiple", $$v)},expression:"dataForModal.is_multiple"}},[_vm._v(" is_multiple ")])],1),_c('b-col',{attrs:{"cols":"4","lg":"3","sm":"2"}},[_c('label'),_c('b-form-checkbox',{model:{value:(_vm.dataForModal.is_draft),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_draft", $$v)},expression:"dataForModal.is_draft"}},[_vm._v(" Is draft ")])],1),_c('b-col',{attrs:{"cols":"4","lg":"3","sm":"3"}},[_c('label'),_c('b-form-checkbox',{model:{value:(_vm.dataForModal.is_required),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_required", $$v)},expression:"dataForModal.is_required"}},[_vm._v(" is_required ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }