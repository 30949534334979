<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import { BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BModal, BRow } from 'bootstrap-vue';
import draggable from 'vuedraggable';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import TableActionBtns from '@/components/TableActionBtns';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    sortItems
} from '@/util/helper';
import vSelect from 'vue-select';

export default {
    name: 'OrganizationSurveySingle',
    components: {
        TableActionBtns,
        PageTitle,
        BButton,
        BModal,
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        vSelect,
        draggable
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        organization: {
            name: ''
        },
        survey: {
            name: ''
        },
        questions: [],
        dataForModal: {
            name: {
                uz: '',
                ru: ''
            },
            hint: {
                uz: '',
                ru: ''
            },
            award_coins: 0,
            position: 0,
            input_type_id: '',
            is_draft: false,
            is_multiple: false,
            is_required: false,
            timer: 10
        },
        organizationID: null,
        surveyID: null,
        inputTypes: []
    }),
    computed: {
        questionsTableHeader() {
            return [
                {
                    key: '#',
                    label: ''
                },
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'hint',
                    label: this.$t('titles.hint')
                },
                {
                    key: 'questions_count',
                    label: this.$t('titles.questions_count')
                },
                {
                    key: 'position',
                    label: this.$t('titles.position')
                },
                {
                    key: 'is_draft',
                    label: this.$t('titles.countDraft')
                },
                {
                    key: 'is_multiple',
                    label: this.$t('titles.multiple')
                },
                {
                    key: 'is_required',
                    label: this.$t('titles.required')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        computedQuestions: {
            get() {
                return this.questions;
            },
            set(newValue) {
                this.questions = newValue;
            }
        }
    },
    methods: {
        checkLang,
        async updateWithData(item) {
            await this.getQuestion();
            this.openModalToUpdate(item);
        },
        async getQuestion() {
            const { data } = await api.questions.getQuestion(this.organizationID, this.surveyID);
            this.dataForModal = data.result;
        },
        async getOrganization() {
            const { data } = await api.organizations.getSingleOrganizationApi(this.organizationID);
            this.organization = data.result;
        },

        async getSurvey() {
            const { data } = await api.surveys.getSingleSurveyApi(
                this.organizationID,
                this.surveyID
            );
            this.survey = data.result;
        },

        async getQuestions() {
            const { data } = await api.questions.getAllQuestionsApi(
                this.organizationID,
                this.surveyID
            );
            this.questions = data.result;
            this.questions = sortItems(this.questions, 'position');
        },

        async create() {
            const { data } = await api.questions.createQuestionApi(
                this.organizationID,
                this.surveyID,
                this.dataForModal
            );
            this.questions.push(data.result);
            this.$toast.success(this.$t('success.added'));
        },

        async update() {
            const { data } = await api.questions.updateQuestionApi(
                this.organizationID,
                this.surveyID,
                this.updatingItem.id,
                this.dataForModal
            );
            this.updateDataToNewData(data.result);
            this.$toast.success(this.$t('success.updated.title'));
        },

        async deleteQuestion(id, index) {
            try {
                await api.questions.deleteQuestionApi(this.organizationID, this.surveyID, id);
                this.questions.splice(index, 1);
                this.$toast.success(this.$t('success.deleted'));
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        async updatePosition() {},

        async getInputTypes() {
            const { data } = await api.inputTypes.getApi();
            this.inputTypes = data.result;
        },

        onOpenModal() {
            if (!this.inputTypes.length) this.getInputTypes();
        },

        openModalToCreate() {
            this.isOpenModal = true;
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    },
    mounted() {
        this.organizationID = this.$route.params.organizationID;
        this.surveyID = this.$route.params.surveyID;
        this.getOrganization();
        this.getSurvey();
        this.getQuestions();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('titles.organization') }}: {{ organization.name }}
                <br />
                {{ $t('titles.survey') }}: {{ checkLang(survey.name) }}
            </template>
            <template v-if="hasAccess('votes', 'create')" #button>
                <b-button class="ml-auto" variant="success" @click="openModalToCreate">
                    {{ $t('add.question') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <div class="card">
            <div class="informations-draggable-table">
                <table>
                    <thead>
                        <tr>
                            <th v-for="(title, index) in questionsTableHeader" :key="index">
                                {{ title.label }}
                            </th>
                        </tr>
                    </thead>
                    <draggable
                        v-model="computedQuestions"
                        handle=".handle"
                        tag="tbody"
                        @change="updatePosition"
                    >
                        <tr v-for="(item, index) in computedQuestions" :key="index">
                            <td>
                                <div class="handle cursor-move">
                                    <feather-icon icon="AlignJustifyIcon" />
                                </div>
                            </td>
                            <td>
                                {{ item.id }}
                            </td>
                            <td>
                                {{ checkLang(item.name) }}
                            </td>

                            <td>
                                {{ checkLang(item.hint) }}
                            </td>

                            <td>
                                {{ item.answers_count }}
                            </td>

                            <td>
                                {{ item.position }}
                            </td>

                            <td>
                                <feather-icon
                                    :class="[
                                        'stroke-current',
                                        generateIconColorToBoolean(item.is_draft)
                                    ]"
                                    :icon="generateIconToBoolean(item.is_draft)"
                                />
                            </td>
                            <td>
                                <feather-icon
                                    :class="[
                                        'stroke-current',
                                        generateIconColorToBoolean(item.is_multiple)
                                    ]"
                                    :icon="generateIconToBoolean(item.is_multiple)"
                                />
                            </td>
                            <td>
                                <feather-icon
                                    :class="[
                                        'stroke-current',
                                        generateIconColorToBoolean(item.is_required)
                                    ]"
                                    :icon="generateIconToBoolean(item.is_required)"
                                />
                            </td>
                            <td>
                                <div class="d-flex justify-content-end">
                                    <b-button
                                        v-b-tooltip.hover.top="`${$t('button.open')}`"
                                        :to="{
                                            name: 'answers',
                                            params: {
                                                organizationID: $route.params.organizationID,
                                                surveyID: $route.params.surveyID,
                                                questionID: item.id
                                            }
                                        }"
                                        class="btn-icon"
                                        variant="flat-dark"
                                    >
                                        <feather-icon icon="ArrowRightCircleIcon" />
                                    </b-button>
                                    <table-action-btns
                                        :delete-access="{ section: 'votes', permission: 'delete' }"
                                        :delete-handler="deleteQuestion"
                                        :index="index"
                                        :item="item"
                                        :update-access="{ section: 'votes', permission: 'update' }"
                                        @openModal="updateWithData"
                                    />
                                </div>
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>

        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="$t('add.survey')"
            cancel-variant="danger"
            centered
            size="xl"
            @hidden="onCloseModal"
            @show="onOpenModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    QUESTION NAME UZ    -->
                    <b-col cols="12" lg="6" sm="6">
                        <label> {{ $t('titles.question') }} (UZ) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.question')} UZ`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} UZ`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    QUESTION NAME RU    -->
                    <b-col cols="12" lg="6" sm="6">
                        <label> {{ $t('titles.question') }} (RU) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.question')} RU`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} RU`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    QUESTION HINT UZ    -->
                    <b-col cols="12" lg="6" sm="6">
                        <label> {{ $t('titles.hint') }} (UZ) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.hint')} UZ`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.hint.uz"
                                    :placeholder="`${$t('enter.title')} UZ`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    QUESTION HINT RU    -->
                    <b-col cols="12" lg="6" sm="6">
                        <label> {{ $t('titles.hint') }} (RU) </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.hint')} RU`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.hint.ru"
                                    :placeholder="`${$t('enter.title')} RU`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <b-col class="mb-1" cols="12" lg="3" sm="4">
                        <label>
                            {{ $t('titles.position') }}
                        </label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.position"
                                :placeholder="$t('enter.position')"
                                size="lg"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    award_coins    -->
                    <b-col class="mb-1" cols="12" lg="3" sm="4">
                        <label>
                            {{ $t('titles.award_coins') }}
                        </label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.award_coins"
                                :placeholder="`${$t('enter.position')}`"
                                size="lg"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    timer    -->
                    <b-col class="mb-1" cols="12" lg="3" sm="4">
                        <label>
                            {{ $t('titles.timer') }}
                        </label>
                        <b-form-group>
                            <b-form-input
                                v-model="dataForModal.timer"
                                :placeholder="`${$t('enter.position')}`"
                                size="lg"
                            />
                        </b-form-group>
                    </b-col>

                    <!--    input_type_id    -->
                    <b-col cols="12" lg="3" sm="4">
                        <label>
                            {{ $t('titles.input_types') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.input_types')"
                            rules="required"
                        >
                            <b-form-group>
                                <v-select
                                    v-model="dataForModal.input_type_id"
                                    :class="[
                                        'select-size-lg',
                                        {
                                            'v-select--is-invalid': errors.length > 0
                                        }
                                    ]"
                                    :menu-props="{
                                        closeOnContentClick: false
                                    }"
                                    :options="inputTypes"
                                    :placeholder="$t('choose.type')"
                                    :reduce="(type) => type.id"
                                    :searchable="false"
                                    label="name"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    is_multiple    -->
                    <b-col cols="4" lg="3" sm="3">
                        <label></label>
                        <b-form-checkbox v-model="dataForModal.is_multiple">
                            is_multiple
                        </b-form-checkbox>
                    </b-col>

                    <!--    IS DRAFT    -->
                    <b-col cols="4" lg="3" sm="2">
                        <label></label>
                        <b-form-checkbox v-model="dataForModal.is_draft">
                            Is draft
                        </b-form-checkbox>
                    </b-col>

                    <!--    IS Required    -->
                    <b-col cols="4" lg="3" sm="3">
                        <label></label>
                        <b-form-checkbox v-model="dataForModal.is_required">
                            is_required
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="modalSubmit">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
::v-deep .modal-dialog.modal-xl .modal-body {
    overflow-y: visible !important;
}
</style>
